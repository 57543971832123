/* Built In Imports */
import { useContext, useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import WrapLayout from '@Layout/WrapLayout';
import Breadcrum from '@components/Navigation/Breadcrum';
import CenterCommonUI from '@components/UI/Center';
import LeftNavCommonUI from '@components/UI/Common/LeftNavCommonUI';
import HeadComponent from '@components/Utility/HeadComponent';
import IndiaChatBot from '@components/Utility/IndiaChatBot';
import USChatBot from '@components/Utility/USChatBot';
import config from '@config';
import { AuthContext } from '@store/auth-context';
import { useRouter } from 'next/router';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { fetchDetails, getLandingPaths } from 'services/centerService';
import {
  fetchFooterData,
  fetchHeaderMenuData,
  fetchHeaderTopMenuData,
} from 'services/commonService';

/* Styles */

/**
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Health Landing Page Data
 */
const CenterDetail = ({ region, language, pageData }) => {
  const authContext = useContext(AuthContext);

  const router = useRouter();
  const [centerUrl, setCenterUrl] = useState(false);
  const [iycUrl, setIycUrl] = useState(false);

  useEffect(() => {
    const centerpages = [
      '/sadhguru-at-iiis',
      '/isha-institute-inner-sciences-usa',
    ];
    const iyccenterpage = ['/isha-yoga-center-coimbatore'];

    setCenterUrl(centerpages.some(pageurl => router.asPath.includes(pageurl)));
    setIycUrl(iyccenterpage.some(pageurl => router.asPath.includes(pageurl)));
  }, [router.asPath]);


  return (
    <WrapLayout pad={0}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Breadcrum
        page={pageData.breadcrumbs}
        alsoin={pageData.alsoin}
        url=""
        region={authContext.region}
        lang={language}
        pageConfig={pageData?.pageConfig}
      />

      <Box as="section" className="page-wrap container-article" p="0">
        <CenterCommonUI
          pageBody={pageData.body}
          region={authContext.region}
          lang={language}
        />
        <LeftNavCommonUI
          pageBody={pageData.bodyLeftNavSections}
          region={authContext.region}
          lang={language}
          isLeftMenu
        />
        <CenterCommonUI
          pageBody={pageData.bodyBottomSection}
          region={authContext.region}
          lang={language}
        />
        {centerUrl && <USChatBot region={authContext.region}></USChatBot>}
        {iycUrl && <IndiaChatBot envName={config.ENV_NAME}/> }
      </Box>
    </WrapLayout>
  );
};

export const getStaticPaths = async () => {
  const centerLandingPaths = config.USE_LOCAL_URL
    ? [
      `/en/center/yogashala`,
      `/en/center/yoga-classes-bangalore`,
      `/en/center/uk`,
      `/en/center/isha-yoga-center-coimbatore`,
      `/en/center/isha-institute-inner-sciences-usa`,
      `/en/center/sadhguru-at-iiis`,
      `/en/center/isha-yoga-center-delhi`,
      `/en/center/welcome-isha-yoga-center-coimbatore`,
      `/en/center/isha-life-hyderabad`,
      `/en/center/112-feet-adiyogi-iyc-whatsapp-community`
    ]
    : await getLandingPaths('L1', '/center/', [
      '/center/consecrated-spaces',
      '/center/local-center',
      '/center/isha-research-center',
    ]);
  return {
    paths: centerLandingPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, center },
  req,
}) => {
  const language = region;
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale('', language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale('', language),
    'iso_non_regional_menu'
  );
  const footerData = await fetchFooterData(
    getLocale('', language),
    'isofooter'
  );
  const pageData = await fetchDetails('', language, `${center}`, {
    preview,
    data: previewData,
  });

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: '',
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      isPreview: preview ? true : false,
    },
  };
};

export default CenterDetail;
