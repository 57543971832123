/* Built In Imports*/
import { useEffect } from 'react';
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components  */
import config from '@config';

/* Services */
/**
 * Renders the Indian ChatBot Component
 *
 * @param
 * @returns {ReactElement} - India Chat Bot Component
 */
export default function IndiaChatBot({envName}) {
  const getScript = url =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onerror = reject;
      script.onload = script.onreadystatechange = function () {
        const loadState = this.readyState;
        if (loadState && loadState !== 'loaded' && loadState !== 'complete')
          return;
        script.onload = script.onreadystatechange = null;
        resolve();
      };
      document.head.appendChild(script);
    });
  useEffect(() => {
    if (typeof PrsSchedule === 'undefined') {
      console.log('PrsSchedule === undefined');
      getScript(
        (envName === 'production'
          ? 'https://d3dqyamsdzq0rr.cloudfront.net/prod/widget/chat/js/sia-ai-chat-widget-soks-global-v2.js'
          : 'https://d3dqyamsdzq0rr.cloudfront.net/widget/chat/js/sia-ai-chat-widget-soks-global-v2.js') 
      );
    }
  });
  return (
    <>
    <Box id={config.ENV_NAME} className="USChatbot">
        <Box
          id="skilai-x2n331-widget-container"
          bot-lang="en"
          bot-id="s_ae5f10"
          env={config.ENV_NAME !== 'production' ? 'qa' : ''}
          bot-auth-key="HYYIYBHSHJSDJSDJK"
          className='skil-ai-v-css'
        ></Box>
    </Box>
    </>
  );
}